export const EvolutionGames = {
  AutoRoulette: {
    href: "/casino/evolution/AutoRoulette",
    code: "1000123",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  TarkaseLightningRulet: {
    href: "/casino/evolution/TarkaseLightningRulet",
    code: "1000316",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  thirtytwoCards: {
    href: "/casino/ezugi/thirtytwoCards",
    code: "228002",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/card/casino057.png", alt: "" },
  },
  Baccarat: {
    href: "/casino/ezugi/Baccarat",
    code: "106",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/baccarat/casino034.png", alt: "" },
  },
  NocommissionSpeedBaccarat: {
    href: "/casino/evolution/NocommissionSpeedBaccarat",
    code: "1000017",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/baccarat/casino034.png", alt: "" },
  },
  Blackjack: {
    href: "/casino/ezugi/Blackjack",
    code: "201",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/blackjack/casino039.png", alt: "" },
  },
  SpeedBlackjack: {
    href: "/casino/evolution/SpeedBlackjack",
    code: "1000033",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/blackjack/casino039.png", alt: "" },
  },
  FereBlackjack: {
    href: "/casino/evolution/FereBlackjack",
    code: "1000066",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/blackjack/casino039.png", alt: "" },
  },
  LightningBlackjack: {
    href: "/casino/evolution/LightningBlackjack",
    code: "1000358",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/blackjack/casino039.png", alt: "" },
  },
  PowerBlackjack: {
    href: "/casino/evolution/PowerBlackjack",
    code: "1000147",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/blackjack/casino039.png", alt: "" },
  },
  Megaball: {
    href: "/casino/evolution/Megaball",
    code: "1000141",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino058.png", alt: "" },
  },
  SideBetCity: {
    href: "/casino/evolution/SideBetCity",
    code: "1000115",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino058.png", alt: "" },
  },
  TopCard: {
    href: "/casino/evolution/TopCard",
    code: "1000144",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino058.png", alt: "" },
  },
  sicbodice01: {
    href: "/casino/ezugi/sicbodice01",
    code: "228001",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino058.png", alt: "" },
  },
  LigntningDice: {
    href: "/casino/evolution/LigntningDice",
    code: "1000076",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino058.png", alt: "" },
  },
  CrazyTime: {
    href: "/casino/evolution/CrazyTime",
    code: "1000148",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  Craps: {
    href: "/casino/evolution/Craps",
    code: "1000248",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DragonTiger1: {
    href: "/casino/evolution/DragonTiger1",
    code: "1000074",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DoubleBallRoulette: {
    href: "/casino/evolution/DoubleBallRoulette",
    code: "1000084",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  FirstPersonAmericanRoulette: {
    href: "/casino/evolution/FirstPersonAmericanRoulette",
    code: "1000312",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  InfiniteBlackjack: {
    href: "/casino/evolution/InfiniteBlackjack",
    code: "1000067",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratA: {
    href: "/casino/evolution/SpeedBaccaratA",
    code: "1000021",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratB: {
    href: "/casino/evolution/SpeedBaccaratB",
    code: "1000022",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratC: {
    href: "/casino/evolution/SpeedBaccaratC",
    code: "1000023",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratD: {
    href: "/casino/evolution/SpeedBaccaratD",
    code: "1000024",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratE: {
    href: "/casino/evolution/SpeedBaccaratE",
    code: "1000025",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratF: {
    href: "/casino/evolution/SpeedBaccaratF",
    code: "1000026",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  supersicbo: {
    href: "/casino/evolution/supersicbo",
    code: "1000110",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino060.png", alt: "" },
  },
  AmericanRoulette: {
    href: "/casino/evolution/AmericanRoulette",
    code: "1000011",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  RouletteVip: {
    href: "/casino/evolution/RouletteVip",
    code: "1000124",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/casino02.png", alt: "" },
  },
  DoubleBallRouletteLive: {
    href: "/casino/evolution/DoubleBallRouletteLive",
    code: "1000084",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/casino070.PNG", alt: "" },
  },
  FirstPersonRoulette: {
    href: "/casino/evolution/FirstPersonRoulette",
    code: "1000118",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/casino070.PNG", alt: "" },
  },

  FootballStudio: {
    href: "/casino/evolution/FootballStudio",
    code: "1000112",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  CashorCrash: {
    href: "/casino/evolution/CashorCrash",
    code: "1000352",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  GonzoTreasureHunt: {
    href: "/casino/evolution/GonzoTreasureHunt",
    code: "1100022",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DreamCatcher: {
    href: "/casino/evolution/DreamCatcher",
    code: "1000077",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  HandCasinoHoldem: {
    href: "/casino/evolution/HandCasinoHoldem",
    code: 507000,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  HandCasinoHoldem2: {
    href: "/casino/evolution/HandCasinoHoldem2",
    code: 1000073,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  CasinoHoldem: {
    href: "/casino/evolution/CasinoHoldem",
    code: 1000075,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  Texasholdem: {
    href: "/casino/evolution/Texasholdem",
    code: 1000111,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  UltimateTexasholdem: {
    href: "/casino/evolution/UltimateTexasholdem",
    code: 1000151,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  ImmersiveRoulette: {
    href: "/casino/evolution/ImmersiveRoulette",
    code: 1000122,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedBaccaratQ: {
    href: "/casino/evolution/SpeedBaccaratQ",
    code: 1000269,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedBaccaratR: {
    href: "/casino/evolution/SpeedBaccaratR",
    code: 1000270,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedBaccaratS: {
    href: "/casino/evolution/SpeedBaccaratS",
    code: 1000271,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedBaccaratP: {
    href: "/casino/evolution/SpeedBaccaratP",
    code: 1000272,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedBaccaratO: {
    href: "/casino/evolution/SpeedBaccaratO",
    code: 1000273,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedBaccaratN: {
    href: "/casino/evolution/SpeedBaccaratN",
    code: 1000274,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  GoldenWealthBaccarat: {
    href: "/casino/evolution/GoldenWealthBaccarat",
    code: 1000355,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedBaccaratM: {
    href: "/casino/evolution/SpeedBaccaratM",
    code: 1000275,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedBaccaratL: {
    href: "/casino/evolution/SpeedBaccaratL",
    code: 1000276,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedBaccaratK: {
    href: "/casino/evolution/SpeedBaccaratK",
    code: 1000277,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedBaccaratN: {
    href: "/casino/evolution/SpeedBaccaratN",
    code: 1000274,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SalonPrivaRoulette: {
    href: "/casino/evolution/SalonPrivaRoulette",
    code: 1000102,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedAutoRouletteEvo: {
    href: "/casino/evolution/SpeedAutoRouletteEvo",
    code: 1000103,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedRouletteEvo: {
    href: "/casino/evolution/SpeedRouletteEvo",
    code: 1000104,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  RouletteEvo: {
    href: "/casino/evolution/RouletteEvo",
    code: 1000094,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  LightningRouletteEvo: {
    href: "/casino/evolution/LightningRouletteEvo",
    code: 1000092,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  AutoRouletteVIPEvo: {
    href: "/casino/evolution/AutoRouletteVIPEvo",
    code: 1000126,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },

  LightningBaccarat: {
    href: "/casino/evolution/LightningBaccarat",
    code: 1000149,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },

  BaccaratA: {
    href: "/casino/evolution/BaccaratA",
    code: 1000012,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  BaccaratB: {
    href: "/casino/evolution/BaccaratB",
    code: 1000013,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  BaccaratC: {
    href: "/casino/evolution/BaccaratC",
    code: 1000014,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  CasinoMarinabaccarat4: {
    href: "/casino/evolution/CasinoMarinabaccarat4",
    code: 32103,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  FirstPersonBaccarat: {
    href: "/casino/evolution/FirstPersonBaccarat",
    code: 1000145,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  FirstPersonLightningBaccarat: {
    href: "/casino/evolution/FirstPersonLightningBaccarat",
    code: 1000357,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SalonPriveBaccarat: {
    href: "/casino/evolution/SalonPriveBaccarat",
    code: 1000020,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SalonPriveBaccaratE: {
    href: "/casino/evolution/SalonPriveBaccaratE",
    code: 1000127,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SalonPriveBaccaratE1: {
    href: "/casino/evolution/SalonPriveBaccaratE1",
    code: 1000159,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SalsaBaccarat1: {
    href: "/casino/evolution/SalsaBaccarat1",
    code: 41100,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SalsaBaccarat2: {
    href: "/casino/evolution/SalsaBaccarat2",
    code: 41101,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SalsaBaccarat3: {
    href: "/casino/evolution/SalsaBaccarat3",
    code: 41102,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SalsaBaccarat4: {
    href: "/casino/evolution/SalsaBaccarat4",
    code: 41103,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedBacartH: {
    href: "/casino/evolution/SpeedBacartH",
    code: 1000028,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedBacartG: {
    href: "/casino/evolution/SpeedBacartG",
    code: 1000027,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedBacartP: {
    href: "/casino/evolution/SpeedBacartP",
    code: 1000272,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  SpeedBacartJ: {
    href: "/casino/evolution/SpeedBacartJ",
    code: 1000129,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
  Super6Baccarat: {
    href: "/casino/evolution/Super6Baccarat",
    code: 130,
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/11.png", alt: "" },
  },
};
