import React from "react";
import { useSelector } from "react-redux";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import PlayBtn from "../../../../assets/images/play-btn.png";
import Christmas from "../../../../assets/images/slots/newCasino/Aloha-Christmas.jpg";
import ClusterPays from "../../../../assets/images/slots/newCasino/Aloha-Cluster-Pays.jpg";
import WildWest from "../../../../assets/images/slots/newCasino/Wild-Wild-West-The-Great-Train-Heist.jpg";
import Slots24 from "../../../../assets/images/slots/newCasino/Turn_Your_Fortune.jpg";
import Slots25 from "../../../../assets/images/slots/newCasino/Twin_Spin_Megaways.jpg";
import SteamTower from "../../../../assets/images/slots/newCasino/Steam-Tower.jpg";
import Rome from "../../../../assets/images/slots/newCasino/Rome-The-Golden-Age.jpg";
import ReelRush from "../../../../assets/images/slots/newCasino/Reel-Rush.jpg";
import BloodSuckers from "../../../../assets/images/slots/newCasino/Blood-Suckers.jpg";
import BloodSuckers2 from "../../../../assets/images/slots/newCasino/Blood-Suckers-2.jpg";
import ButterflyStaxx from "../../../../assets/images/slots/newCasino/Butterfly-Staxx.jpg";
import ButterflyStaxx2 from "../../../../assets/images/slots/newCasino/Butterfly-Staxx-2.jpg";
import PiggyRiches from "../../../../assets/images/slots/newCasino/Piggy-Riches.jpg";
import DarkKing from "../../../../assets/images/slots/newCasino/Dark-King-Forbidden-Riches.jpg";
import JackHammer from "../../../../assets/images/slots/newCasino/Jack-Hammer.jpg";
import JackHammer2 from "../../../../assets/images/slots/newCasino/Jack-Hammer-2-Fishy-Business.jpg";
import DeadorAlive from "../../../../assets/images/slots/newCasino/Dead-or-Alive.jpg";
import DeadorAlive2 from "../../../../assets/images/slots/newCasino/Dead-or-Alive-2.jpg";
import DeadorAlive2FeatureBuy from "../../../../assets/images/slots/newCasino/Dead-or-Alive-2-Feature-Buy.jpg";
import JackandtheBeanstalk from "../../../../assets/images/slots/newCasino/Jack-and-the-Beanstalk.jpg";
import JingleSpin from "../../../../assets/images/slots/newCasino/Jingle-Spin.jpg";
import Elements from "../../../../assets/images/slots/newCasino/Elements-The-Awakening.jpg";
import Hotline from "../../../../assets/images/slots/newCasino/Hotline.jpg";
import Hotline2 from "../../../../assets/images/slots/newCasino/Hotline-2.jpg";
import IrishPotLuck from "../../../../assets/images/slots/newCasino/Irish-Pot-Luck.jpg";
import FruitSpin from "../../../../assets/images/slots/newCasino/Fruit-Spin.jpg";
import Dazzle from "../../../../assets/images/slots/newCasino/Dazzle-Me.jpg";
import Slots12 from "../../../../assets/images/slots/newCasino/divine_fortune_megaways.jpg";
import Slots18 from "../../../../assets/images/slots/newCasino/Gorilla_Kingdom.jpg";
import MegaPyramid from "../../../../assets/images/slots/newCasino/Mega-Pyramid.jpg";
import FinnandtheSwirlySpin from "../../../../assets/images/slots/newCasino/Finn-and-the-Swirly-Spin.jpg";
import Slots15 from "../../../../assets/images/slots/newCasino/Fruit_Shop_Megaways.jpg";
import FruitShop from "../../../../assets/images/slots/newCasino/Fruit-Shop.jpg";
import DazzleMeMegaways from "../../../../assets/images/slots/newCasino/Dazzle-Me.jpg";
import DoubleStacks from "../../../../assets/images/slots/newCasino/Double-Stacks.jpg";
import FairytaleLegends from "../../../../assets/images/slots/newCasino/Fairytale-Legends-Red-Riding-Hood.jpg";
import Flowers from "../../../../assets/images/slots/newCasino/Flowers.jpg";
import FruitShopChristmasEdition from "../../../../assets/images/slots/newCasino/Fruit-Shop-Christmas-Edition.jpg";
import Slots4 from "../../../../assets/images/slots/newCasino/asgardianstones.jpg";
import Slots5 from "../../../../assets/images/slots/newCasino/secrets-of-atlantis.jpg";
import JungleSpirit from "../../../../assets/images/slots/newCasino/Jungle-Spirit-Call-of-the-Wild.jpg";
import KoiPrincess from "../../../../assets/images/slots/newCasino/Koi-Princess.jpg";
import DriveMultiplierMayhem from "../../../../assets/images/slots/newCasino/Drive-Multiplier-Mayhem.jpg";
import RageoftheSeas from "../../../../assets/images/slots/newCasino/Rage-of-the-Seas.jpg";
import RichesofMidgard from "../../../../assets/images/slots/newCasino/Riches-of-Midgard-Land-and-Expand.jpg";
import ScruffyDuck from "../../../../assets/images/slots/newCasino/Scruffy-Duck.jpg";
import SecretoftheStones from "../../../../assets/images/slots/newCasino/Secret-of-the-Stones.jpg";
import SecretsofChristmas from "../../../../assets/images/slots/newCasino/Secrets-of-Christmas.jpg";
import SerengetiKings from "../../../../assets/images/slots/newCasino/Serengeti-Kings.jpg";
import SpaceWars from "../../../../assets/images/slots/newCasino/Space-Wars.jpg";
import Victorious from "../../../../assets/images/slots/newCasino/Victorious.jpg";
import Warlords from "../../../../assets/images/slots/newCasino/Warlords-Crystal-of-Power.jpg";
import WildWater from "../../../../assets/images/slots/newCasino/Wild-Water.jpg";
import WildWorlds from "../../../../assets/images/slots/newCasino/Wild-Worlds.jpg";
import Slots27 from "../../../../assets/images/slots/newCasino/Willys_Hot_Chillies.jpg";
import TheInvisibleMan from "../../../../assets/images/slots/newCasino/The_Invisible_Man.jpg";
import GonzosQuest from "../../../../assets/images/slots/newCasino/Gonzos_Quest.jpg";
import GordonRamsayHellsKitchen from "../../../../assets/images/slots/newCasino/Gordon_Ramsay_Hells_Kitchen.jpg";
import GunsNRoses from "../../../../assets/images/slots/newCasino/Guns_N_Roses.jpg";
import Jumanji from "../../../../assets/images/slots/newCasino/Jumanji.jpg";
import MotorheadVideoSlot from "../../../../assets/images/slots/newCasino/Motorhead_Video_Slot.jpg";
import Starburst from "../../../../assets/images/slots/newCasino/Starburst.jpg";
import Jimi_Hendrix from "../../../../assets/images/slots/newCasino/Jimi_Hendrix.jpg";
import LeftBarSports from "../../../components/LeftBarSports";

const Netent = () => {
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <>
      <HeaderAfterLogin />

      <main className="main">
        <div className="d-xl-none">
          <LeftBarSports />
        </div>

        <div className="slots-section">
          <div className="games-section">
            <div className="game-heading">
              <span>Netent</span>
            </div>
            <ul>
              <li>
                <a href={isAuth ? "/casino/ezugi/Christmas" : "/login"}>
                  <img src={Christmas} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/ClusterPays" : "/login"}>
                  <img src={ClusterPays} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/WildWest" : "/login"}>
                  <img src={WildWest} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/Jimi_Hendrix" : "/login"}>
                  <img src={Jimi_Hendrix} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/Jumanji" : "/login"}>
                  <img src={Jumanji} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href={isAuth ? "/casino/ezugi/MotorheadVideoSlot" : "/login"}
                >
                  <img src={MotorheadVideoSlot} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/Starburst" : "/login"}>
                  <img src={Starburst} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href="/casino/ezugi/TwinSpinMegaways">
                  <img src={Slots25} alt="Live casino game" />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/GunsNRoses" : "/login"}>
                  <img src={GunsNRoses} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href={
                    isAuth ? "/casino/ezugi/GordonRamsayHellsKitchen" : "/login"
                  }
                >
                  <img src={GordonRamsayHellsKitchen} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href="/casino/ezugi/TurnFortune">
                  <img src={Slots24} alt="Live casino game" />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/SteamTower" : "/login"}>
                  <img src={SteamTower} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/Rome" : "/login"}>
                  <img src={Rome} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/ReelRush" : "/login"}>
                  <img src={ReelRush} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/BloodSuckers" : "/login"}>
                  <img src={BloodSuckers} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? " /casino/ezugi/MegaPyramid" : "/login"}>
                  <img src={MegaPyramid} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/ButterflyStaxx" : "/login"}>
                  <img src={ButterflyStaxx} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/GonzosQuest" : "/login"}>
                  <img src={GonzosQuest} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/PiggyRiches" : "/login"}>
                  <img src={PiggyRiches} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/DarkKing" : "/login"}>
                  <img src={DarkKing} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/JingleSpin" : "/login"}>
                  <img src={JingleSpin} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/Dazzle" : "/login"}>
                  <img src={Dazzle} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/JackHammer" : "/login"}>
                  <img src={JackHammer} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/DeadorAlive" : "/login"}>
                  <img src={DeadorAlive} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href={isAuth ? "/casino/ezugi/JackandtheBeanstalk" : "/login"}
                >
                  <img src={JackandtheBeanstalk} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/DeadorAlive2" : "/login"}>
                  <img src={DeadorAlive2} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href="/casino/ezugi/GorillaKingdom">
                  <img src={Slots18} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href="/casino/ezugi/DivinieFortuneMegaways">
                  <img src={Slots12} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/Elements" : "/login"}>
                  <img src={Elements} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/TheInvisibleMan" : "/login"}>
                  <img src={TheInvisibleMan} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href={
                    isAuth ? "/casino/ezugi/FinnandtheSwirlySpin" : "/login"
                  }
                >
                  <img src={FinnandtheSwirlySpin} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href="/casino/ezugi/FruitShopMegaways">
                  <img src={Slots15} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/FruitShop" : "/login"}>
                  <img src={FruitShop} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href="/casino/ezugi/asgardianstones">
                  <img src={Slots4} alt="Live casino game" />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/BloodSuckers2" : "/login"}>
                  <img src={BloodSuckers2} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/ButterflyStaxx2" : "/login"}>
                  <img src={ButterflyStaxx2} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/DazzleMeMegaways" : "/login"}>
                  <img src={DazzleMeMegaways} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href={
                    isAuth ? "/casino/ezugi/DeadorAlive2FeatureBuy" : "/login"
                  }
                >
                  <img src={DeadorAlive2FeatureBuy} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/DoubleStacks" : "/login"}>
                  <img src={DoubleStacks} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/FairytaleLegends" : "/login"}>
                  <img src={FairytaleLegends} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/Flowers" : "/login"}>
                  <img src={Flowers} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href={
                    isAuth
                      ? "/casino/ezugi/FruitShopChristmasEdition"
                      : "/login"
                  }
                >
                  <img src={FruitShopChristmasEdition} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href="/casino/ezugi/secrets">
                  <img src={Slots5} alt="Live casino game" />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/Hotline" : "/login"}>
                  <img src={Hotline} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/Hotline2" : "/login"}>
                  <img src={Hotline2} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/IrishPotLuck" : "/login"}>
                  <img src={IrishPotLuck} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/JackHammer2" : "/login"}>
                  <img src={JackHammer2} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/FruitSpin" : "/login"}>
                  <img src={FruitSpin} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/JungleSpirit" : "/login"}>
                  <img src={JungleSpirit} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/KoiPrincess" : "/login"}>
                  <img src={KoiPrincess} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href={
                    isAuth ? "/casino/ezugi/DriveMultiplierMayhem" : "/login"
                  }
                >
                  <img src={DriveMultiplierMayhem} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/RageoftheSeas" : "/login"}>
                  <img src={RageoftheSeas} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/RichesofMidgard" : "/login"}>
                  <img src={RichesofMidgard} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/ScruffyDuck" : "/login"}>
                  <img src={ScruffyDuck} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/SecretoftheStones" : "/login"}>
                  <img src={SecretoftheStones} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href={isAuth ? "/casino/ezugi/SecretsofChristmas" : "/login"}
                >
                  <img src={SecretsofChristmas} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/SerengetiKings" : "/login"}>
                  <img src={SerengetiKings} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/SpaceWars" : "/login"}>
                  <img src={SpaceWars} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/Victorious" : "/login"}>
                  <img src={Victorious} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/Warlords" : "/login"}>
                  <img src={Warlords} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/WildWater" : "/login"}>
                  <img src={WildWater} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href={isAuth ? "/casino/ezugi/WildWorlds" : "/login"}>
                  <img src={WildWorlds} />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
              <li>
                <a href="/casino/ezugi/WillysChills">
                  <img src={Slots27} alt="Live casino game" />
                  <div className="casino_overlay">
                    <img src={PlayBtn} />
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Netent;
