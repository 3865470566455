import React from "react";
import CashierIcon from "../assets/svg/CashierIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/images/logo.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";
import SupportIcon from "../assets/images/support-icon.svg";
import WhatsappIcon from "../assets/images/whatsapp-icon.svg";
import SkypeIcon from "../assets/images/skype-icon.svg";
import TelegramIcon from "../assets/images/telegram-icon.svg";
import InstagramIcon from "../assets/images/instagram-icon.svg";
import BetfairImg from "../assets/images/betfair-img.png";
import BrowserIcons from "../assets/images/browser-icons.png";
import DownloadApp from "../assets/images/downloadApp-img.png";
import GamesIcon from "../assets/images/icon-game.gif";
import AccountIcon from "../assets/images/icon-account.svg";
import PingIcon from "../assets/images/icon-ping.svg";
import HomeIcon from "../assets/images/icon-home.svg";
import InplayIcon from "../assets/images/icon-inplay.svg";
import SportsIcon from "../assets/images/inplay-sports.svg";
import GCGaming from "../assets/images/gaming-curacao.png";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";
import BottomNav from "./BottomNav";

const Footer = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <footer className="footer cust-container">
      <div class="support_sec">
        <div class="support-box">
          <div class="inner-box">
            <img src={SupportIcon} alt="support" title="customer" />
            <a href="javascript:void(0)" target="_blank">
              Customer support1
            </a>
            <a href="javascript:void(0)" target="_blank">
              support2
            </a>
          </div>
          <div class="inner-box">
            <img src={WhatsappIcon} alt="whatsapp" title="WhatsApp" />
            <a href="javascript:void(0)" target="_blank">
              what's app 3
            </a>
            <a href="javascript:void(0)" target="_blank">
              what's app 4
            </a>
          </div>
        </div>

        <div class="inner-box"></div>

        <div class="support-box">
          <div class="social-box">
            <img src={SkypeIcon} alt="skype" title="Skype" />
            <a href="javascript:void(0)" target="_blank">
              <span className="d-none d-md-block">pushpabookofficial</span>
              <span className="d-md-none">Skype</span>
            </a>
          </div>
          <div class="social-box">
            <img src={TelegramIcon} alt="telegram" title="Email" />
            <a href="mailto:info@pushpabook.com" target="_blank">
              <span className="d-none d-md-block">info@pushpabook.com</span>
              <span className="d-md-none">Email</span>
            </a>
          </div>
          <div class="social-box">
            <img
              src={InstagramIcon}
              alt="instagram"
              title="Instagram"
              className="insta"
            />
            <a href="javascript:void(0)" target="_blank">
              <span className="d-none d-md-block">Pushpabookofficial</span>
              <span className="d-md-none">Instagram</span>
            </a>
          </div>
        </div>
      </div>

      <div class="power-wrap d-none d-md-flex">
        {/* <h3 id="powerWrapW">
          <span>Powered by</span>
          <img src={BetfairImg} alt="betfair" />
        </h3> */}
        <div class="licence_embed">
          <img src={GCGaming} />
        </div>
        <p>
          Pushpabook is operated by Pushpabook liability company incorporated
          under the laws of Curacao with company Registration number 152377 with
          registered office at Abraham de Veerstraat 9 , Curacao P.O Box 3421
          and is licensed and regulated by the Curacao authority as the
          regulatory body responsible holding a (Sub-license with License number
          365/JAZ Sub-License GLH- OCCHKTW0707072017 granted on 6.07.2017).
          <br />
          Players are requested not to contact any untrusted sources for
          pushpabook.com accounts as this is an online site and they can only
          register independently without any agents. Only deposit through the
          account details generated by the system or provided by our official
          support team.
        </p>
      </div>

      <div class="browser-wrap d-none d-md-block">
        <img src={BrowserIcons} alt="browser icons" />
        <br />
        Our website works best in the newest and last prior version of these
        browsers: <br />
        Google Chrome. Firefox
      </div>

      <ul class="policy-link">
        <li>
          <a href="/PrivacyPolicy">Privacy Policy</a>
        </li>
        <li>
          <a href="/Terms">Terms and Conditions</a>
        </li>
        <li>
          <a href="/GamesRule">Rules and Regulations</a>
        </li>
        <li>
          <a href="/fairplay">Fair Play</a>
        </li>
        <li>
          <a href="/ResponsibleGame">Responsible Gaming</a>
        </li>
      </ul>

      {/* <div class="app-link" style={{ display: "block" }}>
        <a href="javascript:void(0)">
          <img src={DownloadApp} alt="download app" />
        </a>
        <p>v1.11 - 2022-03-23 - 3.1MB</p>
      </div> */}

      <div className="container d-none">
        <div className="providers_img">
          <a href="javascript:void(0)" style={{ width: "65px" }}>
            <img src={RNG} loading="lazy" alt="RNG Certified" />
          </a>
          <a href="javascript:void(0)" style={{ width: "80px" }}>
            <img src={Gambling} loading="lazy" alt="Gambling Commission" />
          </a>
          <a href="javascript:void(0)" style={{ width: "130px" }}>
            <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
          </a>
          <a href="javascript:void(0)" style={{ width: "120px" }}>
            <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
          </a>
        </div>

        <div className="helpline">
          <a
            className="panel"
            href={
              appDetails?.WHATS_APP_URL != ""
                ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                : "javascript:void(0)"
            }
            target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
          >
            WhatsApp Us{" "}
          </a>
        </div>

        <div className="footer-links">
          <ul>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicy ">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegame">Responsible Gaming</a>
            </li>
            <li>
              <a href="/fairplay">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrule">Games Rules</a>
            </li>
            <li>
              <a href="/terms">Terms and Conditions</a>
            </li>
          </ul>
        </div>

        <div class="d-flex justify-content-center mt-2">
          <p style={{ color: "rgb(204, 204, 204)" }}>
            Accept, process &amp; disburse digital payments for your business.
          </p>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <img
            src={PaymentMod}
            loading="lazy"
            style={{ width: "250px" }}
            alt="Payment Otions"
          />
        </div>

        <div class="d-flex justify-content-center mt-3 mb-3">
          <img
            src={Betfair}
            loading="lazy"
            class="mb-3 betfair"
            alt="Betfair Logo"
          />
        </div>

        <p>{appDetails?.FOOTER_TEXT || ""}</p>
        <div className="paymentImages d-none">
          <ul>
            <li>
              <img src="./images/sewa.jpeg" />
            </li>
            <li>
              <img src="./images/khalti.jpeg" />
            </li>
          </ul>
        </div>

        <div className="social-icons d-none">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? appDetails?.WHATS_APP_URL
                    : "javascript:void(0)"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                {/* <span className="notification-count">2</span> */}
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://t.me/pushpabook">
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100091669203210"
              >
                <img src="/images/facebook-512.svg" alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/officialpushpabook/"
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <BottomNav />
    </footer>
  );
};

export default Footer;
