import React from "react";
import GamesIcon from "../assets/images/icon-game.gif";
import AccountIcon from "../assets/images/icon-account.svg";
import PingIcon from "../assets/images/icon-ping.svg";
import HomeIcon from "../assets/images/icon-home.svg";
import InplayIcon from "../assets/images/icon-inplay.svg";
import SportsIcon from "../assets/images/inplay-sports.svg";

const BottomNav = () => {
  return (
    <>
      <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-lg-none">
        <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
          <li className="main-nav">
            <a href="/casino/ezugi">
              <img src={GamesIcon} alt="account icon" />
            </a>
          </li>
          <li>
            <a href="/exchange/Cricket">
              <img src={SportsIcon} alt=" icon" />
              <span>Sports</span>
            </a>
          </li>
          <li>
            <a href="/exchange/Inplay">
              <img src={InplayIcon} alt=" icon" />
              <span>In-Play</span>
            </a>
          </li>
          <li>
            <a href="/home">
              <img src={HomeIcon} alt=" icon" class="icon-home" />
              <span>Home</span>
            </a>
          </li>
          <li>
            <a href="/casino/evolution">
              <img src={PingIcon} alt=" icon" />
              <span>Casino</span>
            </a>
          </li>
          <li>
            <a href="/cashier">
              <img src={AccountIcon} alt=" icon" />
              <span>Account</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default BottomNav;
